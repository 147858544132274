import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dashboard,
  PermMedia,
  AccountBalanceWallet,
  People,
  Info,
} from '@mui/icons-material';
import { Path } from '../models/path.model';
import { Right } from './rights';
import {
  Home,
  NavigateToHome,
  About,
  User,
  Profile,
  MediaCloud,
  Album,
  Money,
} from '../pages';

export interface RouteConfigurationModel {
  component: () => ReactElement;
  // eslint-disable-next-line
  drawerIcon?: any;
  title: string;
  path: string;
  allowedFor: Right[];
}

export const GetRoutes = (): RouteConfigurationModel[] => {
  const { i18n } = useTranslation();

  return [
    {
      component: Home,
      drawerIcon: Dashboard,
      title: i18n.t('HOME.TITLE'),
      path: Path.HOME,
      allowedFor: [],
    },
    {
      component: MediaCloud,
      drawerIcon: PermMedia,
      title: i18n.t('MEDIACLOUD.TITLE'),
      path: Path.MEDIACLOUD,
      allowedFor: [Right.USER, Right.ADMIN],
    },
    {
      component: Album,
      title: i18n.t('MEDIACLOUD_ALBUM.TITLE'),
      path: Path.MEDIACLOUD_ALBUM_PARAM,
      allowedFor: [Right.USER, Right.ADMIN],
    },
    {
      component: Money,
      drawerIcon: AccountBalanceWallet,
      title: i18n.t('MONEY.TITLE'),
      path: Path.MONEY,
      allowedFor: [Right.USER, Right.ADMIN],
    },
    {
      component: User,
      drawerIcon: People,
      title: i18n.t('USER.TITLE'),
      path: Path.USER,
      allowedFor: [Right.USER],
    },
    {
      component: Profile,
      title: i18n.t('USER_PROFILE.TITLE'),
      path: Path.USER_PROFILE,
      allowedFor: [Right.USER],
    },
    {
      component: Profile,
      title: i18n.t('USER_PROFILE.TITLE'),
      path: Path.USER_PROFILE_PARAM,
      allowedFor: [Right.USER, Right.ADMIN],
    },
    {
      component: About,
      drawerIcon: Info,
      title: i18n.t('ABOUT.TITLE'),
      path: Path.ABOUT,
      allowedFor: [],
    },
    {
      component: NavigateToHome,
      title: i18n.t('HOME.TITLE'),
      path: Path.ANY_OTHER,
      allowedFor: [],
    },
  ];
};
