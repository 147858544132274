import React from 'react';
import { Link, Outlet } from 'react-router-dom';

export function User() {
  return (
    <>
      <div>
        <h1>This is the user page</h1>
        <div>
          <Link to="profile">Click to view our profile page</Link>
        </div>
      </div>
      <Outlet />
    </>
  );
}
