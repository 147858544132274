import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Theme } from '@mui/material/styles';
import i18n from 'i18next';
import { huHU, enUS, GridLogicOperator } from '@mui/x-data-grid';
import {
  DataGrid as WolftechDataGrid,
  DataGridProps,
  FindAllArgs,
  setQueryOptionsParams,
  DatagridFilterModelOperatorTypeEnum,
} from '@wolftechapp/wolftech-custom-mui-components';
import { isNil, isNumber, isFunction, isEmpty } from 'lodash';
import { updateUser, updateDatagridInitialState } from '../../store/auth';

export const DataGrid = ({
  gridIdentifier,
  dataLoader,
  ...props
}: DataGridProps) => {
  const { authLoading } = useSelector((state: ApplicationState) => state.auth);
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const { datagridInitialState } = useSelector(
    (state: ApplicationState) => state.auth
  );
  const allDatagridInitialState = !isNil(datagridInitialState)
    ? JSON.parse(datagridInitialState || '')
    : '';

  const dispatch = useDispatch<AppDispatch>();

  let langKey = enUS;
  switch (i18n.language) {
    case 'hu':
      langKey = huHU;
      break;
    case 'en':
      langKey = enUS;
      break;
    default:
      break;
  }

  const createGqlParams = (params: setQueryOptionsParams) => {
    const order = {
      orderBy:
        !isNil(params.sortModel) && !isEmpty(params.sortModel)
          ? params.sortModel[0].field
          : 'id',
      orderDirection:
        !isNil(params.sortModel) && !isEmpty(params.sortModel)
          ? params.sortModel[0].sort
          : 'asc',
    };
    let andWhere: FindAllArgs['andWhere'] = [];
    let orWhere: FindAllArgs['orWhere'] = [];
    if (!isNil(params.filterModel)) {
      const conditions = params.filterModel.items.map((item) => {
        return {
          by: item.field,
          for: !isNil(item.value) ? item.value : '',
          operator: item.operator as DatagridFilterModelOperatorTypeEnum,
        };
      });
      andWhere = params.filterModel.logicOperator === 'and' ? conditions : [];
      orWhere = params.filterModel.logicOperator === 'or' ? conditions : [];
    }
    const gqlParams = {
      paginate: {
        ...order,
        skip:
          !isNil(params.page) && !isNil(params.pageSize)
            ? params.page * params.pageSize
            : 0,
        take: params.pageSize,
      },
      andWhere,
      orWhere,
    };

    const mergedInitialGridState = {
      ...allDatagridInitialState.gridIdentifier,
      ...gqlParams,
    };
    dispatch(
      updateDatagridInitialState({
        [gridIdentifier]: mergedInitialGridState,
      })
    );

    return dataLoader && dataLoader(gqlParams);
  };

  const initialTableState = allDatagridInitialState[gridIdentifier] && {
    filterModel: {
      items: allDatagridInitialState[gridIdentifier].andWhere.map(
        (item: { by: string; for: string; operator: string }) => {
          return {
            field: item.by,
            value: !isNil(item.for) ? item.for : '',
            operator: item.operator as DatagridFilterModelOperatorTypeEnum,
          };
        }
      ),
      logicOperator: GridLogicOperator.And,
    },
    // sortModel: [{ field: 'lastName', sort: 'desc' }],
  };

  return (
    <WolftechDataGrid
      consoleLogData={false}
      gridIdentifier={gridIdentifier}
      forcedLoadingState={authLoading}
      initialTableState={initialTableState}
      dataLoader={createGqlParams}
      localeText={langKey.components.MuiDataGrid.defaultProps.localeText}
      restoreState={
        !isNil(user?.datagridState) ? JSON.parse(user?.datagridState || '') : ''
      }
      getStateForSave={(gridState) => {
        console.log({ gridState });
        if (!isNil(user)) {
          const { datagridState: allGridState } = user;
          const mergedGridState = {
            ...JSON.parse(!isNil(allGridState) ? allGridState || '' : '{}'),
            ...gridState,
          };
          dispatch(
            updateUser(user.id, {
              datagridState: JSON.stringify(mergedGridState),
            })
          );
        }
      }}
      dataGridHeightCorrection={
        isNumber(props.dataGridHeightCorrection)
          ? props.dataGridHeightCorrection
          : (theme: Theme) => {
            const extraHeightDifference =
              (isFunction(props.dataGridHeightCorrection) &&
                props.dataGridHeightCorrection(theme)) ||
              0;

            return (
              theme.mixins.pageHeader.appBar.height +
              theme.mixins.page.paddingTop +
              theme.mixins.page.paddingBottom +
              extraHeightDifference
            );
          }
      }
      {...props}
    />
  );
};
