import { t } from 'i18next';
import { ErrorProps } from '@wolftechapp/wolftech-custom-mui-components';

export const errorTranslate = async (errors: ErrorProps[]) => {
  return await Promise.all(
    errors.map(async (error) => {
      return {
        ...error,
        message: t(`ERRORS.${error.message}`),
      };
    })
  );
};
