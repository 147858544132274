import React, { Fragment, useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Navigator,
  LoginInputProps,
  SignUpInputProps,
  LoadingScreen,
} from '@wolftechapp/wolftech-custom-mui-components';
import { Link } from 'react-router-dom';
import { GetRoutes } from './configuration/routes';
import { filterRoutesByRights } from './utils/auth';
import { googleAuth, signUp, login, logout, getAuth } from './store/auth';
import * as yup from 'yup';
import { CodeResponse } from '@react-oauth/google';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

function App() {
  const { i18n } = useTranslation();
  const { authLoading, user } = useSelector(
    (state: ApplicationState) => state.auth
  );
  const userRights = user ? user.userRole : [];
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [title, setTitle] = useState<string | undefined>();
  const routes = filterRoutesByRights(GetRoutes(), userRights);

  useEffect(() => {
    setTitle(routes.find((route) => route.path === location.pathname)?.title);
  }, [routes, location]);

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return (
    <Navigator
      title={title}
      content={
        !authLoading ? (
          <Routes>
            {routes.map(({ path, component: Component }) => {
              return <Route key={path} path={path} element={<Component />} />;
            })}
          </Routes>
        ) : (
          <LoadingScreen />
        )
      }
      menuItems={
        <Fragment>
          {routes
            .filter((el) => el.drawerIcon)
            .map(({ title, path, drawerIcon: DrawerIcon }) => {
              return (
                <Link
                  key={path}
                  to={path}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <DrawerIcon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </Link>
              );
            })}
        </Fragment>
      }
      userMenuDropDownProps={{
        accountSettingsTooltip: i18n.t('ACCOUNT_SETTINGS'),
        authLoading: authLoading,
        user: user && user,
        authDialogProps: {
          titles: {
            menuItemTitle: i18n.t('AUTH.BUTTON_LABEL'),
            authSelectorDialogTitle: i18n.t('AUTH.DIALOG_TITLE'),
            authDialogButtonLabel: i18n.t('AUTH.DIALOG_BUTTON_LABEL'),
          },
          textFieldLabels: {
            email: i18n.t('INPUT_FIELD.LABELS.EMAIL'),
            password: i18n.t('INPUT_FIELD.LABELS.PASSWORD'),
          },
          userPassAuth: {
            validationSchema: yup.object({
              email: yup
                .string()
                .email(i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_NOT_VALID'))
                .required(i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_REQUIRED')),
              password: yup
                .string()
                .min(
                  8,
                  i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_MIN_LENGTH', {
                    length: 8,
                  })
                )
                .required(i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_REQUIRED')),
            }),
            onSubmit: async (loginInputProps: LoginInputProps) => {
              return dispatch(login(loginInputProps));
            },
          },
          authDialogContentText: i18n.t('AUTH.DIALOG_CONTENT_TEXT'),
          googleOAuthConfig: {
            clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENTID ?? '',
            onSuccess: (codeResponse: CodeResponse) => {
              if (codeResponse?.code) {
                dispatch(googleAuth(codeResponse?.code));
              }
            },
            onError: () => {
              console.log('Login Failed');
            },
            buttonProps: {
              children: 'GoogleOAuth',
              variant: 'contained',
              color: 'primary',
            },
          },
        },
        signUpDialogProps: {
          titles: {
            menuItemTitle: i18n.t('SIGN_UP.BUTTON_LABEL'),
            signUpSelectorDialogTitle: i18n.t('SIGN_UP.DIALOG_TITLE'),
            signUpDialogButtonLabel: i18n.t('SIGN_UP.DIALOG_BUTTON_LABEL'),
          },
          signUpDialogContentText: i18n.t('SIGN_UP.DIALOG_CONTENT_TEXT'),
          textFieldLabels: {
            email: i18n.t('INPUT_FIELD.LABELS.EMAIL'),
            emailRepeat: i18n.t('INPUT_FIELD.LABELS.EMAIL_REPEAT'),
            password: i18n.t('INPUT_FIELD.LABELS.PASSWORD'),
            passwordRepeat: i18n.t('INPUT_FIELD.LABELS.PASSWORD_REPEAT'),
            loginName: i18n.t('INPUT_FIELD.LABELS.LOGIN_NAME'),
            firstName: i18n.t('INPUT_FIELD.LABELS.FIRST_NAME'),
            lastName: i18n.t('INPUT_FIELD.LABELS.LAST_NAME'),
          },
          validationSchema: yup.object({
            email: yup
              .string()
              .email(i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_NOT_VALID'))
              .required(i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_REQUIRED')),
            emailRepeat: yup
              .string()
              .email(i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_NOT_VALID'))
              .oneOf(
                [yup.ref('email')],
                i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_NOT_MATCH')
              )
              .required(
                i18n.t('INPUT_FIELD.VALIDATIONS.EMAIL_REPEAT_REQUIRED')
              ),
            password: yup
              .string()
              .min(
                8,
                i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_MIN_LENGTH', {
                  length: 8,
                })
              )
              .required(i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_REQUIRED')),
            passwordRepeat: yup
              .string()
              .min(
                8,
                i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_MIN_LENGTH', {
                  length: 8,
                })
              )
              .oneOf(
                [yup.ref('password')],
                i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_NOT_MATCH')
              )
              .required(
                i18n.t('INPUT_FIELD.VALIDATIONS.PASSWORD_REPEAT_REQUIRED')
              ),
            loginName: yup
              .string()
              .min(
                3,
                i18n.t('INPUT_FIELD.VALIDATIONS.LOGIN_NAME_MIN_LENGTH', {
                  length: 3,
                })
              )
              .required(i18n.t('INPUT_FIELD.VALIDATIONS.LOGIN_NAME_REQUIRED')),
            firstName: yup
              .string()
              .required(i18n.t('INPUT_FIELD.VALIDATIONS.FIRST_NAME_REQUIRED')),
            lastName: yup
              .string()
              .required(i18n.t('INPUT_FIELD.VALIDATIONS.LAST_NAME_REQUIRED')),
          }),
          onSubmit: async (signUpInputProps: SignUpInputProps) => {
            return dispatch(signUp(signUpInputProps));
          },
        },
        logoutMenuItemProps: {
          title: i18n.t('AUTH.LOGOUT_BUTTON_LABEL'),
          onClick: () => {
            dispatch(logout());
          },
        },
      }}
    />
  );
}

export default App;
