import React from 'react';
import { useParams } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';

export function Profile() {
  const { id } = useParams();
  console.log({ id });

  return (
    <>
      <div>
        <h1>This is the profile page</h1>
        <div>
          <Link to="1">Click to view our profile 1 page</Link>
        </div>
      </div>
      <Outlet />
    </>
  );
}
