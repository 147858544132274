import { createSlice } from '@reduxjs/toolkit';
import {
  ErrorProps,
  FindAllArgs,
} from '@wolftechapp/wolftech-custom-mui-components';
import { errorTranslate } from '../utils/error';
import { mediaCloudService } from '../services';
import { AlbumModel } from '../models/index';

export interface MediaCloudState {
  albumsDataLoading: boolean;
  albumsData: { result: AlbumModel[]; totalCount: number };
  albumsDataError: ErrorProps[];
  albumDataLoading: boolean;
  albumData: AlbumModel | undefined;
  albumDataError: ErrorProps[];
  uploadingTrack: boolean;
  uploadingTrackError: ErrorProps[];
  uploadingCover: boolean;
  uploadingCoverError: ErrorProps[];
  /* moviesDataLoading: boolean,
  moviesData: [],
  moviesDataError: ErrorProps[],
  movieDataLoading: boolean,
  movieData: [],
  movieDataError: ErrorProps[], */
  volume: 0.2;
}

const initialState: MediaCloudState = {
  albumsDataLoading: true,
  albumsData: { result: [], totalCount: 0 },
  albumsDataError: [],
  albumDataLoading: true,
  albumData: undefined,
  albumDataError: [],
  uploadingTrack: false,
  uploadingTrackError: [],
  uploadingCover: false,
  uploadingCoverError: [],
  /* moviesDataLoading: true,
  moviesData: [],
  moviesDataError: null,
  movieDataLoading: true,
  movieData: [],
  movieDataError: null, */
  volume: 0.2,
};

const mediaCloudSlice = createSlice({
  name: 'mediaCloud',
  initialState,
  reducers: {
    albumsDataLoading(state, action) {
      state.albumsDataLoading = action.payload;
    },
    albumDataLoading(state, action) {
      state.albumDataLoading = action.payload;
    },
    uploadingTrack(state, action) {
      state.uploadingTrack = action.payload;
    },
    uploadingCover(state, action) {
      state.uploadingCover = action.payload;
    },
    albumsSetData(state, action) {
      state.albumsData = action.payload;
    },
    albumSetData(state, action) {
      state.albumData = action.payload;
    },
    albumsSetError(state, acton) {
      state.albumsDataError = acton.payload;
    },
    albumsErrorConfirmation(state) {
      state.albumsDataError = initialState.albumsDataError;
    },
    albumSetError(state, acton) {
      state.albumDataError = acton.payload;
    },
    albumErrorConfirmation(state) {
      state.albumDataError = initialState.albumDataError;
    },
  },
});

const { actions, reducer } = mediaCloudSlice;

export const { albumsDataLoading, albumsSetError, albumsErrorConfirmation } =
  actions;

export const getAlbums =
  (findAllArgs: FindAllArgs) => async (dispatch: AppDispatch) => {
    dispatch(actions.albumsDataLoading(true));

    let { getAlbums } = await mediaCloudService.getAlbums({
      ...findAllArgs,
      withDeleted: false,
    });
    if (getAlbums?.errors) {
      getAlbums = {
        ...getAlbums,
        errors: await errorTranslate(getAlbums.errors),
      };
      dispatch(actions.albumsSetError(getAlbums.errors));
    } else {
      dispatch(actions.albumsSetData(getAlbums));
      dispatch(actions.albumsErrorConfirmation());
    }

    dispatch(actions.albumsDataLoading(false));
    return getAlbums;
  };

export const getAlbum =
  (albumName: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.albumDataLoading(true));

    let response = await mediaCloudService.getAlbum(albumName);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.albumSetError(response.errors));
    } else {
      dispatch(actions.albumSetData(response.getAlbum));
      dispatch(actions.albumErrorConfirmation());
    }

    dispatch(actions.albumDataLoading(false));
    return response.getAlbum;
  };

export const deleteAlbum =
  (albumIds: string[]) => async (dispatch: AppDispatch) => {
    dispatch(actions.albumsDataLoading(true));

    let response = await mediaCloudService.deleteAlbum(albumIds);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.albumsSetError(response.errors));
    } else {
      dispatch(actions.albumsErrorConfirmation());
    }

    dispatch(actions.albumsDataLoading(false));

    return response;
  };

export const uploadTrack =
  (tracks: File[]) => async (dispatch: AppDispatch) => {
    dispatch(actions.uploadingTrack(true));

    await mediaCloudService.uploadTrack(tracks);

    dispatch(actions.uploadingTrack(false));
  };

export const uploadAlbumCover =
  (cover: File, albumId: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.uploadingCover(true));

    await mediaCloudService.uploadAlbumCover(cover, albumId);

    dispatch(actions.uploadingCover(false));
  };

export const setFavoriteAlbum =
  (albumId: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.albumsDataLoading(true));

    let response = await mediaCloudService.setFavoriteAlbum(albumId);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.albumsSetError(response.errors));
    } else {
      dispatch(actions.albumsErrorConfirmation());
    }

    dispatch(actions.albumsDataLoading(false));

    return response;
  };

export default reducer;
