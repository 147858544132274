import { createSlice } from '@reduxjs/toolkit';
import {
  LoginInputProps,
  SignUpInputProps,
  UpdateUserInputProps,
  ErrorProps,
} from '@wolftechapp/wolftech-custom-mui-components';
import { errorTranslate } from '../utils/error';
import { authorizationService } from '../services';
import { UserModel } from '../models/user.model';

export interface AuthState {
  user?: UserModel;
  datagridInitialState?: string;
  authLoading: boolean;
  error: ErrorProps[];
}

const initialState: AuthState = {
  user: undefined,
  datagridInitialState: undefined,
  authLoading: true,
  error: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate(state, action) {
      state.user = action.payload;
    },
    updateUserState(state, action) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    updateDatagridInitialState(state, action) {
      state.datagridInitialState = JSON.stringify(action.payload);
    },
    loading(state, action) {
      state.authLoading = action.payload;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.error = [];
    },
    setError(state, acton) {
      state.error = acton.payload;
    },
    errorConfirmation(state) {
      state.error = [];
    },
  },
});

const { actions, reducer } = authSlice;

export const {
  authenticate,
  updateUserState,
  loading,
  setError,
  errorConfirmation,
  updateDatagridInitialState,
} = actions;

export const signUp =
  (signUpInputProps: SignUpInputProps) => async (dispatch: AppDispatch) => {
    dispatch(actions.loading(true));

    let response = await authorizationService.signUp(signUpInputProps);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.setError(response.errors));
    } else {
      dispatch(actions.errorConfirmation());
    }

    dispatch(actions.loading(false));
    return response;
  };

export const login =
  (loginInputProps: LoginInputProps) => async (dispatch: AppDispatch) => {
    dispatch(actions.loading(true));

    let response = await authorizationService.login(loginInputProps);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.setError(response.errors));
    } else {
      dispatch(actions.authenticate(response?.login));
      dispatch(actions.errorConfirmation());
    }

    dispatch(actions.loading(false));
    return response;
  };

export const logout = () => async (dispatch: AppDispatch) => {
  dispatch(actions.loading(true));

  await authorizationService.logout();
  dispatch(actions.logout());

  dispatch(actions.loading(false));
};

export const googleAuth = (code?: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.loading(true));

  const { googleAuth } = await authorizationService.googleAuth({ code });
  dispatch(actions.authenticate(googleAuth));

  dispatch(actions.loading(false));
};

export const getAuth = () => async (dispatch: AppDispatch) => {
  dispatch(actions.loading(true));

  const { getAuth, errors } = await authorizationService.getAuth();
  if (getAuth && getAuth.googleAuthRefreshToken) {
    const { googleAuth } = await authorizationService.googleAuth({
      refreshToken: getAuth.googleAuthRefreshToken,
    });
    dispatch(actions.authenticate(googleAuth));
  } else {
    dispatch(actions.authenticate(getAuth));
  }
  if (errors) {
    dispatch(actions.setError(errors));
  }

  dispatch(actions.loading(false));
};

export const updateUser =
  (id: string, updateUserInputProps: UpdateUserInputProps) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.loading(true));

    let response = await authorizationService.updateUser(
      id,
      updateUserInputProps
    );
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.setError(response.errors));
    } else {
      dispatch(actions.updateUserState(response.updateUser));
      dispatch(actions.errorConfirmation());
    }

    dispatch(actions.loading(false));
    return response;
  };

export default reducer;
