import { createTheme } from '@mui/material/styles';
import { amber, green } from '@mui/material/colors';

const dt = createTheme();

const primaryMain = '#002169';

const theme = createTheme({
  mixins: {
    sideBar: {
      closedWidth: 90,
      fullWidth: 300,
      icon: {
        paddingLeft: dt.spacing(1),
        paddingRight: dt.spacing(1.5),
        paddingBottom: dt.spacing(1),
        paddingTop: dt.spacing(1.5),
        size: 40,
        borderWidth: 5,
        borderRadius: 10,
      },
    },
    subSideBar: {
      width: 215,
      paddingLeft: dt.spacing(1),
      paddingRight: dt.spacing(1.5),
      paddingBottom: dt.spacing(1),
      paddingTop: dt.spacing(1.5),
    },
    profileMenu: {
      avatarContainer: {
        paddingLeft: dt.spacing(0),
        paddingRight: dt.spacing(0),
        paddingBottom: dt.spacing(0),
        paddingTop: dt.spacing(0),
      },
    },
    pageHeader: {
      paddingLeft: dt.spacing(3),
      paddingRight: dt.spacing(3),
      paddingBottom: 8,
      paddingTop: 8,
      appBar: {
        height: 80,
      },
    },
    page: {
      paddingLeft: dt.spacing(1),
      paddingRight: dt.spacing(1),
      paddingBottom: 8,
      paddingTop: 8,
      titleHeight: 32,
    },
    breadcrumbs: {
      height: 40,
      paddingLeft: dt.spacing(1),
      paddingRight: dt.spacing(1),
    },
    tabs: {
      tabHeight: 40,
      paddingBottom: 8,
      paddingTop: 8,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          '&$disabled': {
            border: '1px solid',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: dt.spacing(3),
          paddingRight: dt.spacing(3),
          paddingBottom: dt.spacing(2),
          paddingTop: dt.spacing(0),
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            marginLeft: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: primaryMain,
          fontWeight: 'bold',
          fontSize: dt.typography.pxToRem(18),
          '&.Mui-disabled': {
            color: primaryMain,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingLeft: dt.spacing(2),
          paddingRight: dt.spacing(2),
          paddingTop: dt.spacing(1),
          paddingBottom: dt.spacing(1),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: dt.palette.common.white,
          paddingLeft: `${dt.spacing(1)}!important`,
          paddingRight: `${dt.spacing(1)}!important`,
        },
        formControl: {
          '& + .MuiInput-root': {
            marginTop: 22,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
            {
              filter: 'none',
            },
          '& input::-webkit-input-placeholder': {
            fontStyle: 'italic',
            fontSize: dt.typography.pxToRem(16),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            paddingBottom: 0,
          },
          '& .MuiOutlinedInput-input': {
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 2,
          },
          '& svg': {
            marginBottom: 0,
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid !important',
          },
          '&.MuiInput-underline:after, &.MuiInput-underline:hover:not(.Mui-disabled):before':
            {
              borderBottomWidth: 1,
            },
        },
      },
    },
  },
  palette: {
    action: {},
    primary: {
      main: primaryMain,
      dark: '#002169',
      contrastText: '#FFF',
      light: 'rgba(106, 154, 248, 0.08)',
    },
    secondary: {
      main: '#FF0000',
      dark: '#ea0404',
      contrastText: '#FFF',
      light: 'rgba(255, 0, 0, 0.08)',
    },
    success: dt.palette.augmentColor({ color: { main: green[600] } }),
    warning: dt.palette.augmentColor({ color: { main: amber[700] } }),
    error: dt.palette.augmentColor({ color: { main: '#E6384D' } }),
    background: {
      default: dt.palette.common.white,
      paper: dt.palette.common.white,
    },
    text: {
      primary: dt.palette.common.black,
      secondary: dt.palette.common.white,
    },
    common: {},
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 14,
  },
});

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(theme);
}

export default theme;
