async function request<T = void>(requestConfig: {
  url: string;
  credentials?: 'include' | 'omit' | 'same-origin';
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: HeadersInit;
  // eslint-disable-next-line
  body?: any;
}) {
  return new Promise<T>((resolve, reject) => {
    fetch(requestConfig.url, {
      credentials: requestConfig.credentials
        ? requestConfig.credentials
        : 'include',
      method: requestConfig.method ? requestConfig.method : 'GET',
      headers: requestConfig.headers ? requestConfig.headers : {},
      body:
        requestConfig.body &&
        requestConfig.headers &&
        Object.values(requestConfig.headers)[0] === 'application/json'
          ? JSON.stringify(requestConfig.body)
          : requestConfig.body
            ? requestConfig.body
            : null,
    })
      .then(async (response) => {
        return { resData: await response.json(), response };
      })
      .then(({ resData, response }) => {
        if (!response.ok) {
          resolve(resData.errors[0].message);
        }
        resData.data ? resolve(resData.data) : resolve(resData);
      })
      .catch((err) => {
        reject({
          message: 'The connection went offline with the backend!',
          detailedInfo: err,
        });
      });
  });
}

export default request;
