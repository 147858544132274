import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GoogleMap, Calculator } from '@wolftechapp/wolftech-custom-mui-components';
import { Header } from './Header';
import { FormFields } from './FormFields';
import { FormActions } from './FormActions';
import { CreateMoneyModel } from '../../../models';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
    borderRadius: '10px',
    overflowY: 'auto', // Enable scrollbar for modal content
    maxHeight: '90vh',
};

const contentStyle = {
    padding: '16px',
};

interface CreateMoneyModalProps {
    open: boolean;
    onClose: () => void;
    onCreate: (record: CreateMoneyModel) => void;
}

const validationSchema = yup.object({
    type: yup.string()
        .required('Type is required'),
    category: yup.string()
        .required('Category is required'),
    amount: yup.number()
        .required('Amount is required')
        .typeError('Amount must be a number'),
    currency: yup.string()
        .required('Currency is required'),
    note: yup.string()
        .max(255, 'Note must be at most 255 characters'),
});

export const CreateMoneyModal = ({ open, onClose, onCreate }: CreateMoneyModalProps) => {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const { user } = useSelector((state: ApplicationState) => state.auth);
    const [calculatorModalOpen, setCalculatorModalOpen] = useState(false);
    const [calculatorResult, setCalculatorResult] = useState(0);

    const FormInitialValues = {
        type: 0,
        category: 'Food & Drink',
        note: '',
        amount: 0,
        currency: 'HUF',
        walletId: '42c73e4a-e0e9-4043-9649-32b5f24ae7f6',
        userId: user?.id,
    }

    const formik = useFormik({
        initialValues: {
            ...FormInitialValues,
            latitude: 47.4979,
            longitude: 19.0402,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onCreate(values);
            onClose();
        },
    });

    useEffect(() => {
        if (open) {
            setCalculatorResult(FormInitialValues.amount)
            formik.setValues({
                ...FormInitialValues,
                latitude: formik.values.latitude,
                longitude: formik.values.longitude,
            });
        }
    }, [open]);

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    formik.setFieldValue('latitude', position.coords.latitude);
                    formik.setFieldValue('longitude', position.coords.longitude);
                },
                (error) => {
                    console.error(error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
        }
    }, [open]);

    const handleLocationChange = (lat: number, lng: number) => {
        formik.setFieldValue('latitude', lat);
        formik.setFieldValue('longitude', lng);
    };

    const handleCalculatorResult = (value: string) => {
        formik.setFieldValue('amount', Number(value));
        setCalculatorResult(Number(value));
        setCalculatorModalOpen(false);
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Header onClose={onClose} theme={theme} title={i18n.t('MONEY.DATAGRID_BUTTON.CREATE_MONEY')} />
                    <Box sx={contentStyle}>
                        <form onSubmit={formik.handleSubmit}>
                            <FormFields formik={formik} i18n={i18n} setCalculatorModalOpen={setCalculatorModalOpen} />
                            <FormActions onClose={onClose} i18n={i18n} />
                            <GoogleMap
                                latitude={formik.values.latitude}
                                longitude={formik.values.longitude}
                                zoom={18}
                                onLocationChange={handleLocationChange}
                                mapContainerStyle={{
                                    width: '100%',
                                    height: '200px',
                                    marginTop: '16px',
                                }}
                            />
                        </form>
                    </Box>
                </Box>
            </Modal>
            <Modal open={calculatorModalOpen} onClose={() => setCalculatorModalOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 1,
                    borderRadius: '10px',
                }}>
                    <Calculator initialValue={calculatorResult} onResult={handleCalculatorResult} showConfirmButton confirmButtonText={i18n.t('MONEY.CREATE_MONEY_FORM.CALCULATOR.CONFIRM_BUTTON')} />
                </Box>
            </Modal>
        </>
    );
};