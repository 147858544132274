import {
  LoginInputProps,
  SignUpInputProps,
  UpdateUserInputProps,
  ErrorProps,
} from '@wolftechapp/wolftech-custom-mui-components';
import request from '../utils/request';
import { UserModel } from '../models/user.model';

class AuthorizationService {
  private readonly BASE_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_GRAPHQL_URL_POSTFIX}`;

  signUp = async (signUpInputProps: SignUpInputProps) => {
    const graphqlQuery = {
      query: `
            mutation SignUp($email: String!, $password: String!, $loginName: String!, $firstName: String!, $lastName: String!) {
                signUp(signUpInput: {
                    email: $email,
                    password: $password
                    loginName: $loginName
                    firstName: $firstName
                    lastName: $lastName
                }) {
                  id
                  email
                  userRole {
                    role {
                        name
                    }
                  }
                  loginName
                  firstName
                  lastName
                  createdAt
                }
            }
        `,
      variables: {
        ...signUpInputProps,
      },
    };

    return request<{ signUp: Partial<UserModel>; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  login = async (loginInputProps: LoginInputProps) => {
    const graphqlQuery = {
      query: `
            query Login($email: String!, $password: String!) {
                login(loginInput: {
                    email: $email,
                    password: $password
                }) {
                  id
                  loginName
                  email
                  userRole {
                    role {
                        name
                    }
                  }
                  firstName
                  lastName
                  tokenValidTo
                  createdAt
                  datagridState
                }
            }
        `,
      variables: {
        ...loginInputProps,
      },
    };

    return request<{ login: Partial<UserModel>; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  logout = async () => {
    const graphqlQuery = {
      query: `
          query {
            logout
                {
                    id
                    firstName
                    lastName
                }
          }
        `,
    };

    return request<{ logout: Partial<UserModel> }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  googleAuth = async (config: { code?: string; refreshToken?: string }) => {
    const graphqlQuery = {
      query: `
            mutation GoogleAuth($code: String, $refreshToken: String) {
                googleAuth(googleAuthInput: {
                    code: $code,
                    refreshToken: $refreshToken
                }) {
                    id
                    loginName
                    firstName
                    lastName
                    email
                    userRole {
                      role {
                          name
                      }
                    }
                    createdAt
                    picture
                    tokenValidTo
                    googleAuthRefreshToken
                    datagridState
                }
            }
        `,
      variables: {
        code: config.code,
        refreshToken: config.refreshToken,
      },
    };

    return request<{ googleAuth: Partial<UserModel> }>({
      url: `${this.BASE_URL}`,
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  getAuth = async () => {
    const graphqlQuery = {
      query: `
            query {
                getAuth{
                    id
                    loginName
                    firstName
                    lastName
                    email
                    userRole {
                      role {
                          name
                      }
                    }
                    createdAt
                    picture
                    tokenValidTo
                    googleAuthRefreshToken
                    datagridState
                }
            }
        `,
    };

    return request<{ getAuth: Partial<UserModel>; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  updateUser = async (
    id: string,
    updateUserInputProps: UpdateUserInputProps
  ) => {
    const graphqlQuery = {
      query: `
            mutation UpdateUser($id: String!, $updateUserInput: UpdateUserInput!) {
                updateUser(id: $id, updateUserInput: $updateUserInput) {
                  id
                  firstName
                  lastName
                  datagridState
                }
            }
        `,
      variables: {
        id,
        updateUserInput: updateUserInputProps,
      },
    };

    return request<{ updateUser: Partial<UserModel>; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };
}
export const authorizationService = new AuthorizationService();
