import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Path } from '../../models/path.model';
import './Home.css';

export function Home() {
  const { i18n } = useTranslation();

  return (
    <div className="Home">
      <header className="Home-header">
        <h1>{i18n.t('HOME.WELCOME', { framework: 'React' })}</h1>
        <button onClick={() => i18n.changeLanguage('en')}>en</button>
        <button onClick={() => i18n.changeLanguage('hu')}>hu</button>
      </header>
    </div>
  );
}

export function NavigateToHome() {
  return <Navigate to={Path.HOME} replace />;
}
