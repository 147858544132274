import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import {
  MediaPlayer,
  media,
  LoadingScreen,
} from '@wolftechapp/wolftech-custom-mui-components';
import { getAlbum } from '../../../store/media-cloud';

export function Album() {
  const dispatch = useDispatch<AppDispatch>();
  const { albumName } = useParams();
  const { albumDataLoading, albumData } = useSelector(
    (state: ApplicationState) => state.mediaCloud
  );

  useEffect(() => {
    albumName && dispatch(getAlbum(albumName));
  }, [albumName, dispatch]);

  return (
    <>
      {albumDataLoading ? (
        <LoadingScreen />
      ) : (
        <Box sx={{ height: '100%', width: '100%' }}>
          <MediaPlayer
            mediaList={
              albumData
                ? albumData?.tracks.map((track) => {
                    return {
                      mediaType: 'audio/mpeg',
                      ...track,
                    };
                  })
                : []
            }
            favoriteOnChange={(media: media) => {
              console.log(media);
            }}
          />
        </Box>
      )}
    </>
  );
}
