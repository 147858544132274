import React, { useMemo, Fragment, ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  generateColumns,
  DataGridColumnType,
  FindAllArgs,
  DatagridFilterModelOperatorTypeEnum,
} from '@wolftechapp/wolftech-custom-mui-components';
import {
  CalendarMonth,
  Album,
  Audiotrack,
  AccessTime,
  Favorite,
  FavoriteBorder,
  Add,
} from '@mui/icons-material';
import { Box, Button, TextField, InputLabel, Typography } from '@mui/material';
import { AlbumModel } from '../../models';
import { DataGrid } from '../../components/datagrid/datagrid';
import { GridIdentifiersTypeEnum } from '../../components/datagrid/gridIdentifiers';
import {
  getAlbums,
  deleteAlbum,
  uploadTrack,
  uploadAlbumCover,
  setFavoriteAlbum,
} from '../../store/media-cloud';
import { isEmpty, toArray } from 'lodash';

export function MediaCloud() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const { albumsData, albumsDataLoading, uploadingTrack, uploadingCover } =
    useSelector((state: ApplicationState) => state.mediaCloud);
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const [lastGqlParams, setLastGqlParams] = useState<FindAllArgs>();
  const [selectedAlbums, setSelectedAlbums] = useState<string[]>([]);

  // eslint-disable-next-line
  const renderValueWithIcon = (value: string, Icon: any) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Box>{value}</Box>
        <Icon />
      </Box>
    );
  };

  const columns = useMemo(
    () =>
      generateColumns(
        [
          { field: 'id', headerName: 'ID', filterable: true, sortable: true },
          {
            field: 'cover',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.COVER'),
            renderCell: ({ row }: { row: AlbumModel }) => {
              return (
                !isEmpty(row.pictures) && (
                  <img
                    width={100}
                    height={100}
                    src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MEDIA_CLOUD_ALBUMS_URL_POSTFIX}?albumName=${row.name}&disc=&filePath=${row.pictures[0].fileName}`}
                    alt="cover"
                  />
                )
              );
            },
          },
          {
            field: 'name',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.NAME'),
            type: DataGridColumnType.STRING,
            filterable: true,
            sortable: true,
          },
          {
            field: 'userAlbum.isFavourite',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.FAVOURITE'),
            // This type causes console Warning: React does not recognize the `InputLabelProps`
            type: DataGridColumnType.BOOLEAN,
            disableRowClick: true,
            renderCell: ({ row }: { row: AlbumModel }) => {
              return (
                <Button
                  onClick={() => setFavoriteAlbumAction(row.id)}
                  startIcon={
                    row.userAlbum[0].isFavourite ? (
                      <Favorite />
                    ) : (
                      <FavoriteBorder />
                    )
                  }
                />
              );
            },
            filterable: true,
            sortable: true,
          },
          {
            field: 'artists.name',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.ARTISTS'),
            type: DataGridColumnType.STRING,
            renderCell: ({ row }: { row: AlbumModel }) => {
              return (
                <Box>
                  {row.artists.map((artist) => (
                    <Typography key={artist.id}>{artist.name}</Typography>
                  ))}
                </Box>
              );
            },
            filterable: true,
            sortable: true,
          },
          {
            field: 'composers.name',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.COMPOSERS'),
            type: DataGridColumnType.STRING,
            renderCell: ({ row }: { row: AlbumModel }) => {
              return (
                <Box>
                  {row.composers.map((composer) => (
                    <Typography key={composer.id}>{composer.name}</Typography>
                  ))}
                </Box>
              );
            },
            filterable: true,
            sortable: true,
          },
          {
            field: 'releaseYear',
            headerName: i18n.t(
              'MEDIACLOUD.ALBUMS.DATAGRID_HEADER.RELEASE_YEAR'
            ),
            type: DataGridColumnType.NUMBER,
            renderCell: ({ value }) =>
              renderValueWithIcon(value, CalendarMonth),
            filterable: true,
            sortable: true,
          },
          {
            field: 'totalDiscs',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.DISCS'),
            type: DataGridColumnType.NUMBER,
            renderCell: ({ value }) => renderValueWithIcon(value, Album),
            filterable: true,
            sortable: true,
          },
          {
            field: 'totalTracks',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.TRACKS'),
            type: DataGridColumnType.NUMBER,
            renderCell: ({ value }) => renderValueWithIcon(value, Audiotrack),
            filterable: true,
            sortable: true,
          },
          {
            field: 'duration',
            headerName: i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_HEADER.DURATION'),
            type: DataGridColumnType.NUMBER,
            renderCell: ({ value }) => {
              const totalSeconds = value / 1000;
              const hours = Math.floor(totalSeconds / 60 / 60);
              const minutes = Math.floor(totalSeconds / 60 - hours * 60);
              const seconds = Math.floor(
                totalSeconds - (hours * 60 * 60 + minutes * 60)
              );
              const albumDuration = `${hours}h ${minutes}m ${seconds}s`;
              return renderValueWithIcon(albumDuration, AccessTime);
            },
            sortable: true,
          },
        ],
        {
          enableFiltersForAllColumns: false,
          enableSortingForAllColumns: false,
        }
      ),
    [i18n, lastGqlParams] // eslint-disable-line
  );

  const uploadTrackButton = () => {
    return (
      <Fragment key={'uploadTrack'}>
        <InputLabel htmlFor="track-upload-button">
          <Button
            disabled={uploadingTrack}
            color="primary"
            component="span"
            startIcon={<Add />}
          >
            {i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_BUTTON.UPLOAD_TRACK')}
          </Button>
        </InputLabel>
        <TextField
          inputProps={{ accept: 'audio/*', multiple: true }}
          sx={{ display: 'none' }}
          id="track-upload-button"
          type="file"
          onChange={handleTrackUpload}
        />
      </Fragment>
    );
  };

  const handleTrackUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const tracks = toArray(event.target.files);
    await dispatch(uploadTrack(tracks));
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const uploadAlbumCoverButton = (albumId: string) => {
    return (
      <Fragment key={'uploadAlbumCover'}>
        <InputLabel htmlFor={`album-cover-upload-button${albumId}`}>
          <Button color="primary" component="span" startIcon={<Add />}>
            {i18n.t('MEDIACLOUD.ALBUMS.DATAGRID_BUTTON.UPLOAD_COVER')}
          </Button>
        </InputLabel>

        <TextField
          inputProps={{ accept: 'image/*' }}
          sx={{ display: 'none' }}
          id={`album-cover-upload-button${albumId}`}
          type="file"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleAlbumCoverUpload(event, albumId);
          }}
        />
      </Fragment>
    );
  };

  const handleAlbumCoverUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    albumId: string
  ) => {
    const cover = toArray(event.target.files);
    await dispatch(uploadAlbumCover(cover[0], albumId));
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const deleteAlbumAction = async (albumIds: string[]) => {
    await dispatch(deleteAlbum(albumIds));
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const setFavoriteAlbumAction = async (albumId: string) => {
    await dispatch(setFavoriteAlbum(albumId));
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const dataLoader = (gqlParams: FindAllArgs) => {
    const requiredCondition = {
      by: 'userAlbum.user.id',
      for: user ? user.id.toString() : '',
      operator: DatagridFilterModelOperatorTypeEnum.STRING_EQUALS,
    };
    dispatch(
      getAlbums({
        ...gqlParams,
        andWhere: gqlParams.andWhere
          ? [...gqlParams.andWhere, { ...requiredCondition }]
          : [{ ...requiredCondition }],
      })
    );
  };

  return (
    <DataGrid
      gridIdentifier={GridIdentifiersTypeEnum.MEDIACLOUD_ALBUMS}
      unstable_headerFilters={true}
      forcedLoadingState={albumsDataLoading || uploadingTrack || uploadingCover}
      dataLoader={(gqlParams: FindAllArgs) => {
        setLastGqlParams(gqlParams);
        dataLoader(gqlParams);
      }}
      paginationAndfilterMode={'server'}
      paginationConfig={{
        enabled: true,
        rowCount: albumsData.totalCount,
      }}
      disableRowSelectionOnClick
      checkboxSelection={true}
      getSelectedRows={(rows) => {
        const ids = rows.map((row) => row.id);
        setSelectedAlbums(ids);
      }}
      CRUD={{
        delete: {
          enable: true,
          deleteAction: async (rows) =>
            deleteAlbumAction(rows.map((row: AlbumModel) => row.id)),
        },
      }}
      onCellClickAction={(row: AlbumModel) =>
        navigate(`/mediaCloud/album/${row.name}`)
      }
      toolbarActionButtons={[
        uploadTrackButton(),
        selectedAlbums.length === 1 &&
        uploadAlbumCoverButton(selectedAlbums[0]),
      ]}
      columns={columns}
      rows={albumsData.result}
      getRowHeight={() => 100}
    />
  );
}
