import React from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { GetCurrencies } from './configuration/currencies';
import { GetCategories } from './configuration/category';

interface FormFieldsProps {
    formik: any;
    i18n: any;
    setCalculatorModalOpen: (open: boolean) => void;
}

export const FormFields: React.FC<FormFieldsProps> = ({ formik, i18n, setCalculatorModalOpen }) => {
    const { walletsData } = useSelector((state: ApplicationState) => state.money);

    return (
        <>
            <FormControl fullWidth margin="normal" required>
                <InputLabel>{i18n.t('MONEY.CREATE_MONEY_FORM.TYPE')}</InputLabel>
                <Select
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                >
                    <MenuItem value="0">{i18n.t('MONEY.CREATE_MONEY_FORM.TYPE_OPTIONS.EXPENSE')}</MenuItem>
                    <MenuItem value="1">{i18n.t('MONEY.CREATE_MONEY_FORM.TYPE_OPTIONS.INCOME')}</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" required>
                <InputLabel>{i18n.t('MONEY.CREATE_MONEY_FORM.CATEGORY')}</InputLabel>
                <Select
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                >
                    {GetCategories.map((currency, index) => (
                        <MenuItem key={index} value={currency.label}>{currency.label}</MenuItem>)
                    )}
                </Select>
            </FormControl>
            <TextField
                margin="normal"
                fullWidth
                multiline
                rows={2}
                label={i18n.t('MONEY.CREATE_MONEY_FORM.NOTE')}
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.note && Boolean(formik.errors.note)}
                helperText={formik.touched.note && formik.errors.note}
            />
            <TextField
                margin="normal"
                fullWidth
                label={i18n.t('MONEY.CREATE_MONEY_FORM.AMOUNT')}
                name="amount"
                type="tel" // Changed to "tel" to turn off the full on-screen keyboard
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                onClick={() => setCalculatorModalOpen(true)}
                required
            />
            <FormControl fullWidth margin="normal" required>
                <InputLabel>{i18n.t('MONEY.CREATE_MONEY_FORM.CURRENCY')}</InputLabel>
                <Select
                    name="currency"
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.currency && Boolean(formik.errors.currency)}
                >
                    {GetCurrencies.map((currency, index) => (
                        <MenuItem key={index} value={currency.value}>{currency.label}</MenuItem>)
                    )}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" required>
                <InputLabel>{i18n.t('MONEY.CREATE_MONEY_FORM.WALLET')}</InputLabel>
                <Select
                    name="walletId"
                    value={formik.values.walletId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.walletId && Boolean(formik.errors.walletId)}
                >
                    {walletsData.result.map((wallet, index) => (
                        <MenuItem key={index} value={wallet.id}>{`${wallet.name} (${wallet.currency})`}</MenuItem>)
                    )}
                </Select>
            </FormControl>
        </>
    );
};