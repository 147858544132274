import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/translation.json';
import hu from './locales/hu/translation.json';

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  resources: {
    en: {
      translation: en,
    },
    hu: {
      translation: hu,
    },
  },
  detection: { order: ['navigator', 'cookie', 'localStorage'] },
  fallbackLng: 'en',
});

export default i18next;
