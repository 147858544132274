import { intersection, toArray } from 'lodash';
import { RouteConfigurationModel } from '../configuration/routes';
import { Right } from '../configuration/rights';

/**
 * Operates with 'code' in RightModel
 * @param rights - the pool of right for the user, usually selected from the profile
 * @param rightNeeded  - the right or rights that needs to be found
 * @param strict - if there are multiple rights needed, should every right be present in the right pool of the user
 * @returns
 */
export const hasRight = (
  rights: { role: { name: Right } }[],
  rightNeeded: Right[],
  strict: boolean
) => {
  const result = intersection(
    rights?.map((right) => right.role.name),
    toArray(rightNeeded)
  );

  if (!strict) return !!result.length;

  return result.length === toArray(rightNeeded).length;
};

export const filterRoutesByRights = (
  routes: RouteConfigurationModel[],
  rights: { role: { name: Right } }[]
) => {
  return routes.filter((route) => {
    return hasRight(rights, route.allowedFor, true);
  });
};
