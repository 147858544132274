import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface HeaderProps {
    onClose: () => void;
    theme: any;
    title: string;
}

export const Header: React.FC<HeaderProps> = ({ onClose, theme, title }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
            borderBottom: '1px solid #ddd',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        }}>
            <Typography variant="h6" component="h2" fontWeight="bold">
                {title}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: theme.palette.primary.contrastText }}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
};