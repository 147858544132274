import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface FormActionsProps {
    onClose: () => void;
    i18n: any;
}

export const FormActions: React.FC<FormActionsProps> = ({ onClose, i18n }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
                {i18n.t('MONEY.CREATE_MONEY_FORM.CREATE_BUTTON')}
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose}>
                {i18n.t('MONEY.CREATE_MONEY_FORM.CANCEL_BUTTON')}
            </Button>
        </Box>
    );
};