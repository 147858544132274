import {
  FindAllArgs,
  ErrorProps,
} from '@wolftechapp/wolftech-custom-mui-components';
import request from '../utils/request';
import { AlbumModel, TrackModel } from '../models';

class MediaCloudService {
  private readonly BASE_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_GRAPHQL_URL_POSTFIX}`;
  private readonly UPLOAD_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_UPLOAD_URL_POSTFIX}`;
  private readonly ALBUM_FILES_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MEDIA_CLOUD_ALBUMS_URL_POSTFIX}`;

  getAlbums = async (findAllArgs: FindAllArgs) => {
    const graphqlQuery = {
      query: `
            query GetAlbums($findAllArgs: FindAllArgs!) {
                getAlbums(findAllArgs: $findAllArgs) {
                  result {
                    id
                    name
                    slug
                    releaseYear
                    totalDiscs
                    totalTracks
                    duration
                    artists {
                        id
                        name
                    }
                    composers {
                        id
                        name
                    }
                    userAlbum {
                      user {
                        id
                        loginName
                      }
                      isFavourite
                    }
                    tracks {
                        name
                        slug
                        mediaFile
                        discNumber
                        trackNumber
                        duration
                    }
                    pictures {
                      fileName
                      type
                    }
                  }
                  totalCount
                }
            }
        `,
      variables: {
        findAllArgs,
      },
    };

    return request<{
      getAlbums: { result: AlbumModel[]; errors?: ErrorProps[] };
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  getAlbum = async (albumName: string) => {
    const graphqlQuery = {
      query: `
            query GetAlbum($findAlbumInput: FindAlbumInput!) {
                getAlbum(findAlbumInput: $findAlbumInput) {
                  id
                  name
                  createdAt
                  updatedAt
                  totalDiscs
                  artists {
                      name
                  }
                  userAlbum {
                      user {
                          id
                          loginName
                      }
                      isFavourite
                  }
                  tracks {
                      name
                      slug
                      mediaFile
                      discNumber
                      trackNumber
                      duration
                  }
                }
            }
        `,
      variables: {
        findAlbumInput: { name: albumName },
      },
    };

    const response = await request<{
      getAlbum: AlbumModel;
      errors?: ErrorProps[];
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });

    response.getAlbum = {
      ...response.getAlbum,
      tracks: response.getAlbum.tracks.map((track) => {
        return {
          ...track,
          mediaFile: `${this.ALBUM_FILES_URL}?albumName=${
            response.getAlbum.name
          }&disc=${
            response.getAlbum.totalDiscs > 1 ? track.discNumber : ''
          }&filePath=${track.mediaFile}`,
        };
      }),
    };

    return response;
  };

  deleteAlbum = async (albumIds: string[]) => {
    const graphqlQuery = {
      query: `
            mutation RemoveAlbums($ids: [String!]!) {
              removeAlbums(ids: $ids) {
                albums {
                  name
                  releaseYear
                  totalDiscs
                  artists {
                      name
                  }
                  tracks {
                      trackNumber
                      name
                      mediaFile
                  }
                }
                result
              }
            }
        `,
      variables: {
        ids: albumIds,
      },
    };

    return request<{
      removeAlbum: { albums: AlbumModel[]; result: string };
      errors?: ErrorProps[];
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  uploadTrack = async (tracks: File[]) => {
    const formData: FormData = new FormData();

    for (const track of tracks) {
      formData.append('tracks', track, track.name);
    }

    return request<TrackModel[]>({
      url: `${this.UPLOAD_URL}/music`,
      method: 'POST',
      body: formData,
    });
  };

  uploadAlbumCover = async (cover: File, albumId: string) => {
    const formData: FormData = new FormData();

    formData.append('albumCover', cover, cover.name);

    return request<TrackModel[]>({
      url: `${this.UPLOAD_URL}/albumCover/${albumId}`,
      method: 'POST',
      body: formData,
    });
  };

  setFavoriteAlbum = async (albumId: string) => {
    const graphqlQuery = {
      query: `
            mutation SetFavoriteAlbum($id: String!) {
              setFavoriteAlbum(id: $id) {
                name
                releaseYear
                totalDiscs
                artists {
                    name
                }
                tracks {
                    trackNumber
                    name
                    mediaFile
                }
                userAlbum {
                    user {
                        id
                        loginName
                    }
                    isFavourite
                }
              }
            }
        `,
      variables: {
        id: albumId,
      },
    };

    return request<{ setFavoriteAlbum: AlbumModel; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };
}
export const mediaCloudService = new MediaCloudService();
