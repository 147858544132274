import { createSlice } from '@reduxjs/toolkit';
import {
  ErrorProps,
  FindAllArgs,
} from '@wolftechapp/wolftech-custom-mui-components';
import { errorTranslate } from '../utils/error';
import { moneyService } from '../services';
import { WalletModel, MoneyModel, CreateMoneyModel } from '../models/index';

export interface MoneyState {
  walletsDataLoading: boolean;
  walletsData: { result: WalletModel[]; totalCount: number };
  walletsDataError: ErrorProps[];
  moneiesDataLoading: boolean;
  moneiesData: { result: MoneyModel[]; totalCount: number };
  moneiesDataError: ErrorProps[];
  moneyDataLoading: boolean;
  moneyData: MoneyModel | undefined;
  moneyDataError: ErrorProps[];
}

const initialState: MoneyState = {
  walletsDataLoading: true,
  walletsData: { result: [], totalCount: 0 },
  walletsDataError: [],
  moneiesDataLoading: true,
  moneiesData: { result: [], totalCount: 0 },
  moneiesDataError: [],
  moneyDataLoading: true,
  moneyData: undefined,
  moneyDataError: [],
};

const moneySlice = createSlice({
  name: 'money',
  initialState,
  reducers: {
    walletsDataLoading(state, action) {
      state.walletsDataLoading = action.payload;
    },
    moneiesDataLoading(state, action) {
      state.moneiesDataLoading = action.payload;
    },
    moneyDataLoading(state, action) {
      state.moneyDataLoading = action.payload;
    },
    walletsSetData(state, action) {
      state.walletsData = action.payload;
    },
    moneiesSetData(state, action) {
      state.moneiesData = action.payload;
    },
    moneySetData(state, action) {
      state.moneyData = action.payload;
    },
    walletsSetError(state, acton) {
      state.walletsDataError = acton.payload;
    },
    walletsErrorConfirmation(state) {
      state.walletsDataError = initialState.walletsDataError;
    },
    moneiesSetError(state, acton) {
      state.moneiesDataError = acton.payload;
    },
    moneiesErrorConfirmation(state) {
      state.moneiesDataError = initialState.moneiesDataError;
    },
    moneySetError(state, acton) {
      state.moneyDataError = acton.payload;
    },
    moneyErrorConfirmation(state) {
      state.moneyDataError = initialState.moneyDataError;
    },
  },
});

const { actions, reducer } = moneySlice;

export const { moneiesDataLoading, moneiesSetError, moneiesErrorConfirmation } =
  actions;

export const getWallets =
  (findAllArgs?: FindAllArgs) => async (dispatch: AppDispatch) => {
    dispatch(actions.walletsDataLoading(true));

    let { getWallets } = await moneyService.getWallets({
      ...findAllArgs,
      withDeleted: false,
    });
    if (getWallets?.errors) {
      getWallets = {
        ...getWallets,
        errors: await errorTranslate(getWallets.errors),
      };
      dispatch(actions.walletsSetError(getWallets.errors));
    } else {
      dispatch(actions.walletsSetData(getWallets));
      dispatch(actions.walletsErrorConfirmation());
    }

    dispatch(actions.walletsDataLoading(false));
    return getWallets;
  };

export const createMoney =
  (createMoneyProps: CreateMoneyModel) => async (dispatch: AppDispatch) => {
    dispatch(actions.moneiesDataLoading(true));

    let response = await moneyService.createMoney(createMoneyProps);
    console.log({ response });
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.moneiesSetError(response.errors));
    } else {
      dispatch(actions.moneiesErrorConfirmation());
    }

    dispatch(actions.moneiesDataLoading(false));
    return response;
  };

export const getMoneies =
  (findAllArgs: FindAllArgs) => async (dispatch: AppDispatch) => {
    dispatch(actions.moneiesDataLoading(true));

    let { getMoneies } = await moneyService.getMoneies({
      ...findAllArgs,
      withDeleted: false,
    });
    if (getMoneies?.errors) {
      getMoneies = {
        ...getMoneies,
        errors: await errorTranslate(getMoneies.errors),
      };
      dispatch(actions.moneiesSetError(getMoneies.errors));
    } else {
      dispatch(actions.moneiesSetData(getMoneies));
      dispatch(actions.moneiesErrorConfirmation());
    }

    dispatch(actions.moneiesDataLoading(false));
    return getMoneies;
  };

export const getMoney = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.moneyDataLoading(true));

  let response = await moneyService.getMoney(id);
  if (response?.errors) {
    response = {
      ...response,
      errors: await errorTranslate(response.errors),
    };
    dispatch(actions.moneySetError(response.errors));
  } else {
    dispatch(actions.moneySetData(response.getMoney));
    dispatch(actions.moneyErrorConfirmation());
  }

  dispatch(actions.moneyDataLoading(false));
  return response.getMoney;
};

export const removeMoneies =
  (moneyIds: string[]) => async (dispatch: AppDispatch) => {
    dispatch(actions.moneiesDataLoading(true));

    let response = await moneyService.removeMoneies(moneyIds);
    if (response?.errors) {
      response = {
        ...response,
        errors: await errorTranslate(response.errors),
      };
      dispatch(actions.moneiesSetError(response.errors));
    } else {
      dispatch(actions.moneiesErrorConfirmation());
    }

    dispatch(actions.moneiesDataLoading(false));

    return response;
  };

export default reducer;
