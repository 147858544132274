import i18n from '../../../../i18n';

export const GetCurrencies = [
  {
    value: 'HUF',
    label: i18n.t('MONEY.CREATE_MONEY_FORM.CURRENCY_OPTIONS.HUF'),
  },
  {
    value: 'EUR',
    label: i18n.t('MONEY.CREATE_MONEY_FORM.CURRENCY_OPTIONS.EUR'),
  },
  {
    value: 'GBP',
    label: i18n.t('MONEY.CREATE_MONEY_FORM.CURRENCY_OPTIONS.GBP'),
  },
  {
    value: 'USD',
    label: i18n.t('MONEY.CREATE_MONEY_FORM.CURRENCY_OPTIONS.USD'),
  },
];
