import i18n from '../../../../i18n';

export const GetCategories = [
  {
    icon: 'restaurant',
    color: '#fa983a',
    label: 'Food & Drink',
  },
  {
    icon: 'shopping',
    color: '#b71540',
    label: 'Shopping',
  },
  {
    icon: 'train-car',
    color: '#f6b93b',
    label: 'Transport',
  },
  {
    icon: 'home',
    color: '#e58e26',
    label: 'Home',
  },
  {
    icon: 'flight',
    color: '#e55039',
    label: 'Travel',
  },
  {
    icon: 'person',
    color: '#4a69bd',
    label: 'Family & Personal',
  },
  {
    icon: 'medical-bag',
    color: '#eb2f06',
    label: 'Healthcare',
  },
  {
    icon: 'gift',
    color: '#b8e994',
    label: 'Gifts',
  },
  {
    icon: 'soccer',
    color: '#78e08f',
    label: 'Sport & Hobbies',
  },
  {
    icon: 'google-controller',
    color: '#1e3799',
    label: 'Video Games',
  },
  {
    icon: 'business',
    color: '#2f3640',
    label: 'Work',
  },
  {
    icon: 'lock-question',
    color: '#2f3640',
    label: 'Other',
  },
  {
    icon: 'business',
    color: '#f6b93b',
    label: 'Work',
  },
  {
    icon: 'gift',
    color: '#78e08f',
    label: 'Gifts',
  },
  {
    icon: 'tshirt-crew',
    color: '#78e08f',
    label: 'Clothing',
  },
  {
    icon: 'school',
    color: '#079992',
    label: 'Education',
  },
  {
    icon: 'bolt',
    color: '#78e08f',
    label: 'Services',
  },
  {
    icon: 'filmstrip',
    color: '#1e3799',
    label: 'Cinema',
  },
  {
    icon: 'filmstrip',
    color: '#b8e994',
    label: 'Movie Disc',
  },
  {
    icon: 'laptop',
    color: '#38ada9',
    label: 'Miner',
  },
  {
    icon: 'music',
    color: '#f6b93b',
    label: 'Music Disc',
  },
];
