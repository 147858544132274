import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import authReducer from './auth';
import mediaCloudReducer from './media-cloud';
import moneyReducer from './money';

const reducer = {
  auth: authReducer,
  mediaCloud: mediaCloudReducer,
  money: moneyReducer,
};

const store = configureStore({
  reducer,
  middleware:
    process.env.NODE_ENV === 'development'
      ? (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
      : undefined,
});

declare global {
  type ApplicationState = ReturnType<typeof store.getState>;

  type AppDispatch = typeof store.dispatch;
}

export default store;
